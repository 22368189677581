import {
  DOCUMENT,
  FACEBOOK_PIXEL_ID,
  FORM_CSS_CLASS,
  GOOGLE_MAPS_KEY,
  PromotionsState,
  SERVICES_API,
  SERVICES_BEARER_TOKEN,
  SERVICES_CONFIRMATION_LINK,
  SERVICES_CUSTOMER_TOKEN,
  SERVICES_OLD_API,
  SERVICES_UPLOAD_FILE_LOCATION,
  SYNERISE_KEY,
  UserService,
  UserState,
  UserStateLogout,
  UserStateSetUserData,
  WINDOW,
} from '@am-canteens/data';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule, Store } from '@ngxs/store';
import { provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { EMPTY, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { AuthGuard } from './guards/auth.guard';
import { RECAPTCHA_LOADER_OPTIONS, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { getDocument, getWindow } from 'ssr-window';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SsrGuardService } from './services/ssr-guard.service';
import {
  APP_COOKIES_KEY,
  APP_INFO_ABOUT_PRODUCT_REGISTRATION_NUMBER_OF_VIEWS,
  APP_JWT_TOKEN,
  APP_STATE,
  APP_TOKEN_EXPIRED_AT,
} from './app.consts';
import { DiscountCodesGuard } from './guards/discount-codes.guard';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';

const LOCAL_STORAGE_STATE_VALUES = [
  APP_JWT_TOKEN,
  APP_TOKEN_EXPIRED_AT,
  APP_COOKIES_KEY,
  APP_INFO_ABOUT_PRODUCT_REGISTRATION_NUMBER_OF_VIEWS,
  APP_STATE,
];

registerLocaleData(localePl);

const NGXS_MODULES = [
  NgxsModule.forRoot([UserState, PromotionsState], {
    developmentMode: !environment.production,
  }),
  NgxsRouterPluginModule.forRoot(),
  NgxsFormPluginModule.forRoot(),
  NgxsLoggerPluginModule.forRoot({
    logger: console,
    collapsed: false,
    disabled: environment.production,
  }),
  NgxsStoragePluginModule.forRoot({
    storage: StorageOption.LocalStorage,
    key: [...LOCAL_STORAGE_STATE_VALUES],
  }),
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    RecaptchaV3Module,
    // for NgxIntlTelInputModule
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 15000,
      positionClass: 'toast-top-right',
    }),
    AppRoutingModule,
    ...NGXS_MODULES,
  ],
  providers: [
    AuthGuard,
    SsrGuardService,
    DiscountCodesGuard,
    provideNgxMask(),
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: FACEBOOK_PIXEL_ID, useValue: environment.facebookPixelId },
    { provide: WINDOW, useValue: getWindow() },
    { provide: DOCUMENT, useValue: getDocument() },
    { provide: SERVICES_API, useValue: environment.api },
    { provide: SERVICES_OLD_API, useValue: environment.oldApi },
    { provide: SERVICES_BEARER_TOKEN, useValue: environment.bearerToken },
    { provide: SERVICES_CONFIRMATION_LINK, useValue: environment.confirmationLink },
    { provide: SERVICES_UPLOAD_FILE_LOCATION, useValue: environment.uploadFilesLocation },
    { provide: GOOGLE_MAPS_KEY, useValue: environment.googleMapsKey },
    { provide: SYNERISE_KEY, useValue: environment.syneriseKey },
    { provide: FORM_CSS_CLASS, useValue: 'amm-form' },
    {
      provide: SERVICES_CUSTOMER_TOKEN,
      useFactory: (store: Store) => () => store.selectSnapshot(UserState.token),
      deps: [Store],
    },
    {
      provide: RECAPTCHA_LOADER_OPTIONS,
      useFactory: (locale: string) => ({
        onBeforeLoad(url: URL) {
          url.searchParams.set('hl', locale);
          return { url };
        },
      }),
      deps: [LOCALE_ID],
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaV3SiteKey,
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: APP_INITIALIZER,
      useFactory: (userService: UserService, store: Store) => async () => {
        const { token } = store.selectSnapshot(UserState);
        // token has incorrect format
        // if (!token || isJwtTokenExpired(token)) {
        if (!token) {
          store.dispatch(new UserStateLogout());
          return EMPTY;
        }
        return await firstValueFrom(userService.getMe(token))
          .then((user) => {
            store.dispatch(new UserStateSetUserData({ user }));
          })
          .catch((reason) => {
            if (reason?.status === 403) {
              store.dispatch(new UserStateLogout());
            }
          });
      },
      deps: [UserService, Store],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
