import { Component, HostListener, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationEnd, Router } from '@angular/router';
import { skip } from 'rxjs';
import { NetworkService } from './services/network.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'amm-root',
  template: ` <router-outlet />`,
})
@UntilDestroy()
export class AppComponent implements OnInit {
  constructor(
    private readonly _ngbModal: NgbModal,
    private readonly _router: Router,
    private readonly _networkService: NetworkService,
    private readonly _toastrService: ToastrService,
  ) {}

  @HostListener('window:beforeinstallprompt', ['$event'])
  public onBeforeInstallPrompt(e: Event): void {
    e.preventDefault();
  }

  public async ngOnInit(): Promise<void> {
    this._setRouterSubscription();
    this._setOnlineOfflineChangesSubscription();
  }

  private _setOnlineOfflineChangesSubscription(): void {
    this._networkService.onlineChanges$.pipe(untilDestroyed(this), skip(1)).subscribe((online) => {
      if (online) {
        this._toastrService.info('Odzyskano połączenie internetowe.', 'Połączenie internetowe');
      } else {
        this._toastrService.warning('Utracono połączenie internetowe.', 'Połączenie internetowe');
      }
    });
  }

  private _setRouterSubscription(): void {
    this._router.events.pipe(untilDestroyed(this)).subscribe((routeEvent) => {
      if (routeEvent instanceof NavigationEnd) {
        this._ngbModal.dismissAll();
      }
    });
  }
}
