import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserStateLogout } from '@am-canteens/data';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly _toastrService: ToastrService,
    private readonly _store: Store,
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const statusTimeout = [504, 408, 0];
        if (statusTimeout.includes(error.status)) {
          this._toastrService.warning('Przekroczono limit czasu żądania, spróbuj ponownie', 'Błąd');
        } else if (error.status === 403) {
          this._store.dispatch(new UserStateLogout());
        }
        return throwError(() => error);
      }),
    );
  }
}
