import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () => import('./core/core.routing').then(({ ROUTES }) => ROUTES),
        },
      ],
      {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: QuicklinkStrategy,
        initialNavigation: 'enabledBlocking',
      },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
