import { UntypedFormControl } from '@angular/forms';
import { ValidationResult } from './password.validator';

export class UrlValidator {
  public static url(control: UntypedFormControl): ValidationResult {
    const url = /^(http|https):\/\/[^ "]+$/.test(control.value);
    if (!url) {
      return {
        url: true,
      };
    }
    return null;
  }
}
