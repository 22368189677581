import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, SYNERISE_KEY, WINDOW } from './tokens';
import { Event, NavigationEnd } from '@angular/router';
import { SynDtoTypes, SyneriseActionEnum } from '../synerise';
import { v4 as uuidv4 } from 'uuid';

declare let SR: any;
declare let SyneriseTC: any;

@Injectable({
  providedIn: 'root',
})
export class SyneriseService {
  public readonly ID_SYN_SCRIPT = 'SYNscript';

  constructor(
    @Inject(SYNERISE_KEY)
    private readonly _syneriseKey: string,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    @Inject(WINDOW)
    private readonly _window: Window,
  ) {}

  public removeFromDom(): void {
    if (!this._scriptExists()) {
      return;
    }
    this._document.getElementById(this.ID_SYN_SCRIPT).remove();
  }

  public addScriptToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!this._syneriseKey) {
        reject('Lack of synerise key.');
        return;
      }
      if (this._scriptExists()) {
        resolve(true);
        return;
      }

      this._createDataLayer();
      const synScript = this._document.createElement('script');
      synScript.id = this.ID_SYN_SCRIPT;
      synScript.defer = true;
      synScript.async = true;
      synScript.onload = resolve;
      synScript.onerror = reject;
      synScript.innerHTML = `
      function onSyneriseLoad() {
          SR.init({
              'trackerKey': '${this._syneriseKey}',
              'dataLayer': dataLayer,
              'customPageVisit': true,
              'trackingDomain': 'https://snr-api.amica.pl',
              'domain': '.amica.pl',
              'dynamicContent': {
                  'virtualPage': true
              }
          });
          SR.event.pageVisit().then(function () { SR.dynamicContent.get();  });
      }

      (function(s,y,n,e,r,i,se){s['SyneriseObjectNamespace']=r;s[r]=s[r]||[],
       s[r]._t=1*new Date(),s[r]._i=0,s[r]._l=i;var z=y.createElement(n),
       se=y.getElementsByTagName(n)[0];z.async=1;z.src=e;se.parentNode.insertBefore(z,se);
       z.onload=z.onreadystatechange=function(){var rdy=z.readyState;
       if(!rdy||/complete|loaded/.test(z.readyState)){s[i]();z.onload = null;
       z.onreadystatechange=null;}};})(window,document,'script',
       'https://snr-sdk.amica.pl/synerise-javascript-sdk.min.js','SR', 'onSyneriseLoad');
  `;
      document.body.appendChild(synScript);
    });
  }

  public async sendPageVisit(routeEvent: Event): Promise<void> {
    if (routeEvent instanceof NavigationEnd && this._window['SR'] && SR?.event) {
      await SR.event.pageVisit().catch((error) => {
        console.warn('SR pageVisit', error);
      });
      this.syneriseTCInit();
    }
  }

  /**
   * If an HTML form with data-synerise attributes appears after the tracking code has been initialized (for example, in a pop-up window),
   * you have to explicitly initialize another search for the attributes on the page, using the following SDK method:
   */
  public syneriseTCInit(): void {
    if (!this._window['SyneriseTC'] || !this._window['SR']) {
      return;
    }

    const _initAndGetContent = () => {
      SR.dynamicContent.get();
      SR.dynamicContent.getRendered();
      SyneriseTC.initFormCatch();
    };

    _initAndGetContent();
    setTimeout(() => {
      _initAndGetContent();
    }, 1000);
  }

  public sendFormData(syneriseAction: SyneriseActionEnum, dto: SynDtoTypes): void {
    if (!this._window['SyneriseTC'] || !this._scriptExists()) {
      return;
    }
    this.syneriseTCInit();
    if (!SR?.event) {
      return;
    }
    SR.event.sendFormData(syneriseAction, dto, null, null, (...args) => {
      console.log('-> sendFormData callback', args);
    });
  }

  public setNewUuid(): void {
    if (!this._window['SyneriseTC'] || !this._scriptExists()) {
      return;
    }
    this.syneriseTCInit();
    const uuidv = uuidv4();
    this._document.cookie = `_snrs_reset_uuid=${uuidv}`;
  }

  public setNewUuidAndReloadPage(): void {
    this.setNewUuid();
    this._window.location.reload();
  }

  private _scriptExists(): boolean {
    const script = this._document.getElementById(this.ID_SYN_SCRIPT);
    return !!script;
  }

  private _createDataLayer(): void {
    this._window['dataLayer'] = this._window['dataLayer'] || [];
  }
}
