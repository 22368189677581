export enum RouteSlug {
  HOME = '',
  PROMOTIONS = 'promocje',
  PROMOTIONS_HISTORY = 'historia-promocji',
  ADDRESS_DATA = 'dane-adresowe',
  CONFIGURATIONS = 'konfigurator',
  SERVICE = 'serwis',
  REPORT_DAMAGE = 'serwis/zglos-awarie',
  PARTNER_STORES = 'sklepy-partnerskie',
  DISCOUNT_CODES = 'kody-rabatowe',
  MY_PRODUCTS = 'moje-produkty',
  LOGIN_BY_SSO = 'sso',
  PRODUCT_REGISTRATION = 'rejestracja-produktu',
  SURVEY = 'ankieta',
  SURVEYS = 'ankiety',
  NOT_FOUND_PAGE = '404',
  SITE_UNDER_CONSTRUCTION = 'prace-techniczne',
  PRIVACY_POLICY = 'polityka-prywatnosci',
  COOKIES_POLICY = 'polityka-cookies',
  EMAIL_PREVIEW = 'podglad-wiadomosci',
  SSR_REDIRECT = 'ssr-redirect',

  // promotions
  PRODUCTS_COVERED = 'lista-produktow',
  RULES = 'zasady',
  DATA_PROCESSING = 'przetwarzanie-danych',
  FAQ = 'faq',
  WITHOUT_REGISTRATION = 'bez-rejestracji',
  PRODUCTS_REGISTRATION = 'rejestracja-produktow',
  REGISTRATION = 'rejestracja',

  // active
  FIVE_YEARS = '5latgwarancji',
  BLIK = 'plytazwracasiewblik',
  SATISFACTION = 'satysfakcja',
  ADD_COLOR = 'dodajkolor',
  OPINION_WORTH_BLIK = 'opiniawartablika',

  // not active
  // EVERY_KITCHEN_IS_A_REWARD = 'cokuchniatonagroda',
  // TIME_FOR_COUPLE = 'poranapare',
  // WE_VACUUM_UP_TO_A_PAIR = 'odkurzamydopary',
  // PROFIT_ON_START = 'zyskajnastart',
  // PROFIT_ON_START_BARANOWO = 'zyskajnastart-baranowo',
  // PROFIT_ON_START_POZNAN = 'zyskajnastart-poznan',
  // PERFECT_COUPLE = 'paradoskonala',
  // KITCHEN_FULL_OF_PROFIT = 'kuchniapelnazysku',
  // PROFIT_IN_SET = 'zyskwkomplecie',
  // DRY_CHOICE = 'susznywybor',
  // SETS_COMES_IN_PAIRS = 'zestawychodzaparami',
}
