import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// Sentry.init({
//   dsn: 'https://43dbc89f9dfb4067a91e74fcbdda9716@sentry.h2h.tech/4',
//   integrations: [
//     new BrowserTracing({
//       tracingOrigins: ['https://test-moja.amica.pl/', 'https://moja.amica.pl'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   debug: false,
//   autoSessionTracking: true,
//   normalizeDepth: 7,
//   beforeSend(event) {
//     if (environment.production === false) {
//       console.warn('Sentry event', event);
//     }
//     if (event.user) {
//       delete event.user.email;
//     }
//     return event;
//   },
// });

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule, { ngZoneEventCoalescing: true })
    .catch((err) => console.error(err));
});
