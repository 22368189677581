import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, FACEBOOK_PIXEL_ID } from './tokens';

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  public readonly ID_FBP_SCRIPT = 'FBPscript';
  public readonly ID_FBP_NOSCRIPT = 'FBPno-script';

  private readonly _renderer2: Renderer2;

  constructor(
    @Inject(FACEBOOK_PIXEL_ID)
    private readonly _facebookPixelId: string,
    @Inject(DOCUMENT)
    private readonly _document: Document,
    private readonly _rendererFactory: RendererFactory2,
  ) {
    this._renderer2 = _rendererFactory.createRenderer(null, null);
  }

  public addScriptToDom(): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!this._facebookPixelId) {
        reject('Lack of facebook pixel id.');
        return;
      }
      if (this._scriptExists()) {
        resolve(true);
        return;
      }

      const fbPixelScript = this._renderer2.createElement('script');
      fbPixelScript.id = this.ID_FBP_SCRIPT;
      fbPixelScript.defer = true;
      fbPixelScript.async = true;
      fbPixelScript.onload = resolve;
      fbPixelScript.onerror = reject;
      fbPixelScript.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${this._facebookPixelId}');
          fbq('track', 'PageView');`;

      const fbPixelNoScript = this._renderer2.createElement('noscript');
      fbPixelNoScript.id = this.ID_FBP_NOSCRIPT;
      fbPixelNoScript.innerHTML = `<img alt="fb-pixel" height="1" width="1" src="https://www.facebook.com/tr?id=${this._facebookPixelId}&ev=PageView&noscript=1"/>`;
      this._renderer2.insertBefore(this._document.head, fbPixelNoScript, this._document.head.firstChild);
      this._renderer2.insertBefore(this._document.head, fbPixelScript, this._document.head.firstChild);
    });
  }

  public removeFromDom(): void {
    if (!this._scriptExists()) {
      return;
    }
    this._renderer2.removeChild(this._document.head, this._document.getElementById(this.ID_FBP_SCRIPT));
    this._renderer2.removeChild(this._document.head, this._document.getElementById(this.ID_FBP_NOSCRIPT));
  }

  private _scriptExists(): boolean {
    const script = this._document.getElementById(this.ID_FBP_SCRIPT);
    return !!script;
  }
}
