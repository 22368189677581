import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IRegisteredProductDataItem, UserState } from '@am-canteens/data';
import { RouteSlug } from '../core/_models/route-slug.enum';

@Injectable({
  providedIn: 'root',
})
export class DiscountCodesGuard {
  constructor(private readonly _store: Store) {}

  public canActivate(): Observable<boolean> {
    const registeredProducts: IRegisteredProductDataItem[] = this._store.selectSnapshot(UserState.registeredProducts);
    if (!registeredProducts || registeredProducts?.length === 0) {
      return this._goToHomePage();
    }
    return of(true);
  }

  private _goToHomePage(): Observable<boolean> {
    return of(false).pipe(
      tap(() => {
        this._store.dispatch(new Navigate([RouteSlug.HOME]));
      }),
    );
  }
}
