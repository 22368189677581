import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, WINDOW } from './tokens';

declare const Cookiebot: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  public readonly ID_GTM_IFRAME = 'GTMiframe';

  constructor(
    @Inject(WINDOW)
    public readonly _window: Window,
    @Inject(DOCUMENT)
    private readonly _document: Document,
  ) {}

  public getDataLayer(): any {
    this._createDataLayer();
    return this._window['dataLayer'];
  }

  public addIframeScriptToDom(gtmId: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (!gtmId) {
        reject('Lack of gtm key.');
        return;
      }
      if (this._scriptExists()) {
        resolve(true);
        return;
      }

      this._createDataLayer();
      // this._pushOnDataLayer({
      //   'gtm.start': new Date().getTime(),
      //   event: 'gtm.js',
      // });
      //
      // is added in the index.html file
      // const gtmScript = this._document.createElement('script');
      // gtmScript.id = this.ID_GTM_SCRIPT;
      // gtmScript.defer = true;
      // gtmScript.async = true;
      // gtmScript.onload = resolve;
      // gtmScript.onerror = reject;
      // gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${gtmId}`;
      // this._document.head.insertBefore(gtmScript, this._document.head.firstChild);

      const iframe = this._document.createElement('iframe');
      iframe.setAttribute('src', `//www.googletagmanager.com/ns.html?id=${gtmId}`);
      iframe.style.width = '0';
      iframe.style.height = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      const noscript = this._document.createElement('noscript');
      noscript.id = this.ID_GTM_IFRAME;
      noscript.appendChild(iframe);

      this._document.body.insertBefore(noscript, this._document.body.firstChild);
    });
  }

  public removeFromDom(): void {
    if (!this._scriptExists()) {
      return;
    }
    this._document.getElementById(this.ID_GTM_IFRAME).remove();
  }

  public async pushTag(item): Promise<void> {
    if (!this._scriptExists()) {
      throw new Error('GTM script not found.');
    }
    this._pushOnDataLayer(item);
  }

  private _scriptExists(): boolean {
    const script = this._document.getElementById(this.ID_GTM_IFRAME);
    return !!script;
  }

  private _createDataLayer(): void {
    this._window['dataLayer'] = this._window['dataLayer'] || [];
  }

  private _pushOnDataLayer(obj): void {
    const dataLayer = this.getDataLayer();
    dataLayer.push(obj);
  }
}
